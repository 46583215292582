<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card>
          <template v-slot:headerTitle>
            <h4 class="card-title"><b>MODIFICAR CICLO</b></h4>
          </template>
          <h4 class="px-4 py-4"><b>Información General</b></h4>
          <div class="row px-5">
            <div class="col-xl-6">
              <div>
                <label for=" " class="form-label">Nombre Ciclo</label>
                <input placeholder="Escriba el Numero de Documento..." type="text" class="form-control" id=" " disabled />
              </div>
            </div>
            <div class="col-xl-6">
              <div class="mb-3">
                <label for=" " class="form-label">Empresa</label>
                <b-form-select v-model="selected" :options="options"></b-form-select>
              </div>
            </div>

            <div class="col-xl-12 mb-4">
              <label for=" " class="form-label">Descripción</label>
              <b-form-textarea max-rows="5" id="textarea-default" placeholder="Escriba una descripción de la competencia..."></b-form-textarea>
            </div>

            <div class="col-xl-6">
              <div>
                <label for=" " class="form-label">Fecha inicial</label>
                <input placeholder="Fecha inicial" type="date" class="form-control" id=" " />
              </div>
            </div>
            <div class="col-xl-6 mb-4">
              <div>
                <label for=" " class="form-label">Fecha final</label>
                <input placeholder="Fecha inicial" type="date" class="form-control" id=" " />
              </div>
            </div>
          </div>
          <div class="text-right px-5 pb-4">
            <button class="mx-1 btn px-4 py-2 btn-primary">Guardar Persona</button>
            <button class="mx-1 btn px-4 py-2 btn-secondary">Cancelar Registro</button>
          </div>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from "../../../config/pluginInit";
export default {
  name: "DataTable",
  mounted() {
    core.index();
    window.$("#datatable_empresa").DataTable();
  },
  methods: {},
  data() {
    return {
      tabla_test: [
        {
          id: 1,
          numero_doc: "252527",
          nombre: "LOG2 Demo",
          contraseñas_rep: "721189",
          cargo: "Operario de Conducción",
          estado: "Activo",
        },
        {
          id: 3,
          numero_doc: "252527",
          nombre: "LOG2 Demo",
          contraseñas_rep: "721189",
          cargo: "Operario de Conducción",
          estado: "Activo",
        },
        {
          id: 4,
          numero_doc: "252527",
          nombre: "LOG2 Demo",
          contraseñas_rep: "721189",
          cargo: "Operario de Conducción",
          estado: "Activo",
        },
        {
          id: 5,
          numero_doc: "252527",
          nombre: "LOG2 Demo",
          contraseñas_rep: "721189",
          cargo: "Operario de Conducción",
          estado: "Activo",
        },
        {
          id: 6,
          numero_doc: "252527",
          nombre: "LOG2 Demo",
          contraseñas_rep: "721189",
          cargo: "Operario de Conducción",
          estado: "Activo",
        },
      ],
    };
  },
};
</script>
